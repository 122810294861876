<template>
  <HgCruiseSearch
    allowHotjarRecord
    :is-mobile="isMobile"
    :is-loading-destinations="isLoading"
    :cruiseLines="cruiseLines"
    :search-url="searchUrl"
    :lang="lang"
    :regions="regions"
    :showCooperation="showCooperation"
    :theme="theme"
    @onUpdateSearchParameters="updateSearchParameters"
  />
</template>

<script>
import CruiseCompassCruiseLinesQuery from "../../../apollo/queries/cruise-compass/CruiseCompassCruiseLines.gql"
import CruiseCompassRegionsQuery from "../../../apollo/queries/cruise-compass/CruiseCompassRegions.gql"
import CruiseCompassSearchInput from "../../../apollo/queries/cruise-compass/CruiseCompassSearchInput.gql"
import { addDays, formatISO } from "date-fns"

export default {
  data: () => ({
    destinationTerm: "",
    isLoading: false,
    suggestedDestinations: [],
    searchUrl: "",
    orderedDestinations: [],
    lang: {},
    cruiseLines: [],
    regions: [],
    serachData: {
      dateRange: null,
      region: null,
      cruiseLine: null,
      travelers: { adults: null, ages: [] }
    },
    isMobile: false
  }),

  props: {
    showCooperation: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: null
    }
  },

  created() {
    this.lang = {
      cruiseLineLabel: this.$i18n.t("cruiseCompass.cruiseLineOverview"),
      cruiseLinePlaceholder: this.$i18n.t("cruiseCompass.placeholder"),
      regionLabel: this.$i18n.t("cruiseCompass.regionsOverview"),
      regionPlaceholder: this.$i18n.t("cruiseCompass.placeholder")
    }
    this.getCruiseLines()
    this.getRegions()

    this.isMobile = window.innerWidth < 768
  },

  mounted() {
    window.addEventListener("resize", () => {
      if (!window) {
        return
      }
      this.isMobile = window.innerWidth < 768
    })
  },

  methods: {
    updateSearchParameters(data) {
      this.serachData = data
      this.getUrl(data)
    },
    debounce(func, delay) {
      clearTimeout(this.timerId)
      this.timerId = setTimeout(func, delay)
    },
    async getCruiseLines() {
      try {
        const {
          data: { cruiseCompassCruiseLines }
        } = await this.$apollo.query({
          query: CruiseCompassCruiseLinesQuery
        })

        this.cruiseLines = cruiseCompassCruiseLines.sort((a, b) =>
          a.name.localeCompare(b.name)
        )
      } catch (e) {
        console.log("error", e.graphQLErrors)
      }
    },
    async getRegions() {
      try {
        const {
          data: { cruiseCompassRegions }
        } = await this.$apollo.query({
          query: CruiseCompassRegionsQuery,
          variables: {
            query: ""
          }
        })

        this.regions = cruiseCompassRegions.sort((a, b) =>
          a.name.localeCompare(b.name)
        )
      } catch (e) {
        console.log("error", e.graphQLErrors)
      }
    },
    async getUrl(data) {
      const { dateRange, region, cruiseLine, travelers } = data

      const input = {
        agesOfChildren: travelers?.ages,
        cruiseLineIds: cruiseLine?.map(line => line.id),
        startDate: formatISO(
          addDays(dateRange.start, 1)
        ),
        endDate: formatISO(
          addDays(dateRange.end, 1)
        ),
        numberOfAdults: travelers?.adults,
        regionIds: region?.map(reg => reg.id)
      }

      try {
        const {
          data: { cruiseCompassSearchUrl }
        } = await this.$apollo.query({
          query: CruiseCompassSearchInput,
          variables: {
            input
          }
        })

        this.searchUrl = cruiseCompassSearchUrl
      } catch (e) {
        console.log("error", e.graphQLErrors)
      }
    }
  }
}
</script>
