import Vue from "vue"
import { VLazyImagePlugin } from "v-lazy-image"
import vueCustomElement from "vue-custom-element"
import * as components from "@uniqgmbh/holidayguru-components"

Vue.config.productionTip = false

// Font Awesome
import "./plugins/font-awesome"

// i18n
import { i18n } from "./plugins/i18n"

// Apollo
import { apolloProvider } from "./plugins/apollo"

// Styles
// import "./styles/index.css";
import "@uniqgmbh/holidayguru-components/dist/holidayguru-components.css"

// Widgets
import DealWidget from "./widgets/Deals.vue"
import HgRaffle from "./widgets/Raffle.vue"
import HgSystemNotification from "./widgets/SystemNotification.vue"
import HgNavbarWidget from "./widgets/Navbar.vue"
import HgSimpleNavbarWidget from "./widgets/SimpleNavbar.vue"
import HgFooterWidget from "./widgets/Footer.vue"
import DealPartnerWidget from "./widgets/DealPartnerWidget.vue"
import HgDealBannerWidget from "./widgets/DealBanner.vue"
import HgSearchTabsWidget from "./widgets/SearchTabs.vue"
import HgTrustpilotButtonsWidget from "./widgets/TrustpilotButtons.vue"
import HgAmadeusPackageTourSearch from "./widgets/AmadeusPackageTourSearch.vue"
import HgAmadeusHotelTourSearch from "./widgets/AmadeusHotelTourSearch.vue"
import HgShortTripSearch from "./widgets/ShortTripSearch.vue"
import HgCruiseCompassSearch from "./widgets/CruiseCompassSearch.vue"

Vue.use(VLazyImagePlugin)

// Assign Providers to Widgets
DealWidget.apolloProvider = apolloProvider
HgRaffle.apolloProvider = apolloProvider
HgSystemNotification.apolloProvider = apolloProvider
DealPartnerWidget.apolloProvider = apolloProvider
HgDealBannerWidget.apolloProvider = apolloProvider
HgSearchTabsWidget.apolloProvider = apolloProvider
HgAmadeusPackageTourSearch.apolloProvider = apolloProvider
HgAmadeusHotelTourSearch.apolloProvider = apolloProvider
HgShortTripSearch.apolloProvider = apolloProvider
HgCruiseCompassSearch.apolloProvider = apolloProvider

HgRaffle.i18n = i18n
HgNavbarWidget.i18n = i18n
HgSimpleNavbarWidget.i18n = i18n
HgFooterWidget.i18n = i18n
DealWidget.i18n = i18n
DealPartnerWidget.i18n = i18n
HgDealBannerWidget.i18n = i18n
HgSearchTabsWidget.i18n = i18n
HgAmadeusPackageTourSearch.i18n = i18n
HgAmadeusHotelTourSearch.i18n = i18n
HgShortTripSearch.i18n = i18n
HgCruiseCompassSearch.i18n = i18n

Object.keys(components).forEach(componentName => {
  Vue.component(componentName, components[componentName])
})

// Use Plugins
Vue.use(vueCustomElement)

// Define Element
Vue.customElement("hg-search-tabs-widget", HgSearchTabsWidget)
Vue.customElement("hg-deal-widget", DealWidget)
// Vue.customElement("hg-top-deal-widget", TopDealWidget);
// Vue.customElement("hg-state-guidelines-widget", HgSateGuidelines);
Vue.customElement("hg-raffle-widget", HgRaffle)
// Vue.customElement("hg-system-notification-widget", HgSystemNotification);
Vue.customElement("hg-navbar-widget", HgNavbarWidget)
Vue.customElement("hg-simple-navbar-widget", HgSimpleNavbarWidget)
Vue.customElement("hg-footer-widget", HgFooterWidget)

Vue.customElement("hg-deal-partner-widget", DealPartnerWidget)
Vue.customElement("hg-deal-banner-widget", HgDealBannerWidget)
Vue.customElement("hg-trustpilot-buttons-widget", HgTrustpilotButtonsWidget)

Vue.customElement("hg-amadeus-package-tour-search", HgAmadeusPackageTourSearch)
Vue.customElement("hg-amadeus-hotel-tour-search", HgAmadeusHotelTourSearch)
Vue.customElement("hg-short-trip-search", HgShortTripSearch)
Vue.customElement("hg-cruise-compass-search", HgCruiseCompassSearch)

setTimeout(() => {
  if (document) {
    // document.body.classList.add("holidayguru");
    if (document.querySelector(".vue-portal-target"))
      document.querySelector(".vue-portal-target").classList.add("holidayguru")
    // NO IDEA WHY WE HACK LIKE THAT
    // document.querySelector("html").style.fontSize = "16px";
  }
}, 300)
